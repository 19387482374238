<template>
  <div class="bu-ju-3 bg-fff">
    <div class="top">
      <header-selete @submit="souSuo"/>
    </div>
    <div class="body din-wei">
      <div class="nav-left">
        <div class="nav-left-item"
             v-for="(vo,index) in navList" :key="vo.id"
             @click="actionIndex = index"
             :class="{action:index=== actionIndex}"
        >
          <div class="van-ellipsis">{{ vo.name }}</div>
        </div>
      </div>
      <div class="nav-right">
        <div class="">
          <van-row :gutter="20">
            <van-col class="xiang-xi"
                     @click="$router.push({name:'FuWuDaTing',query:{type_2:vo.id,type_name_2:vo.name}})"
                     :span="8"
                     v-for="vo in getbodyList" :key="vo.id"
            >
              <div class="image">
                <van-image
                    fit="contain"
                    class="image-icon"
                    v-if="vo.image?.length"
                    :src="vo.image[0].url"
                />
              </div>
              <div class="name van-multi-ellipsis--l2">
                {{ vo.name }}
              </div>
            </van-col>
          </van-row>
        </div>
      </div>
    </div>
    <div class="footer">
      <footer-nav/>
    </div>
  </div>
</template>

<script>
import HeaderSelete from "../open/headerSelete";
import FooterNav from "../open/footerNav";

export default {
  name: 'QuanBuType',
  components: {FooterNav, HeaderSelete},
}
</script>
<script setup>
import {ref, computed} from 'vue'
import http from "../../api/http";
import axios from "axios";
import {useRouter} from "vue-router"

const router = useRouter()
let navList = ref([])
let actionIndex = ref(0) //选中的坐标

let getbodyList = computed(() => {
  if (typeof navList.value[actionIndex.value] === 'undefined') return []
  return navList.value[actionIndex.value].children
})

function souSuo(keyword) {
  router.push({name: 'FuWuDaTing', query: {keyword: keyword}})
}

//获取数据
axios.all([
  http.get('/gzhphp/guang_gao/getXiangMuType'),
  http.get('/gzhphp/guang_gao/getHotXiangMuTYpe')
]).then(axios.spread((item2, hot) => {
  navList.value = [
    ...[
      {
        name: '热门推荐',
        id: 0,
        children: hot.data
      }
    ],
    ...item2.data
  ]
  console.log(navList.value)
}))
</script>

<style scoped lang="less">
@import "../../assets/basis_css/style/var";

.din-wei {
  position: relative;
  display: flex;

  .nav-left {
    background-color: @gray-3;
    flex: 0 0 140px;
    overflow-x: auto;

    .nav-left-item {
      font-size: 14px;
      padding: 15px 0;
      flex: 0 0 1;

      &.action {
        background-color: @white;

        div {
          border-left-color: @blue;
        }
      }

      div {


        border-left: 3px solid @gray-3;
        padding-left: 10px;
        padding-right: 5px;
        line-height: 18px;
        height: 18px;
      }
    }
  }

  .nav-right {
    padding: 0 10px;
    overflow-x: auto;
    width: 100%;

    .xiang-xi {
      text-align: center;
      margin-top: 20px;
      margin-bottom: 15px;

      .image {
        //width: 58px;

        .image-icon {
          width: 35px;
          height: 42px;
        }
      }

      .name {
        margin-top: 11px;
      }
    }
  }
}
</style>
